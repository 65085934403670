import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	Button,
	Text,
	Flex,
	Collapse,
	Box,
	Icon,
	VStack,
	FormLabel,
	HStack,
	Spacer,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
	CommonAttributes,
	IChannelMediaAttributes,
	ICreative,
	ImageLayer,
	TextLayer,
} from 'src/lib/schemas/campaign/newFlowCampaign';
import { renderCreativesByPlacement } from './utils/renderCreativesByPlacement';
import { formatLabel } from './utils/formatInputLabel';
import InputField from './components/InputField';
import { attributeConfig } from './utils/attributesConfig';
import { IChannelCreativeAttributes, ThreadTypesEnum } from 'src/lib/schemas';
import { SelectSearchInput } from 'src/components/common/form';
import CustomModal from 'src/components/common/CustomModal';
import { AssistantChatContext, CampaignContext } from 'src/contexts';
import MediaCreativeModal from 'src/components/campaign/DesignOutput/parts/MediaCreativeModal';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import UserContext from 'src/contexts/UserContext';
import { getCompanyAccount } from 'src/services/account';
import {
	ICreativePayload,
	IPerview,
} from 'src/components/campaign/DesignOutput/parts/DesignDirections/parts/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { customToast, toastError, toastSuccess } from 'src/services/toast';
import http from 'src/services/http';
import { updateCampaignCreative } from 'src/services/campaign';
import CustomInput from 'src/components/common/CustomInput/CustomInput';
import { useAssistantField } from '../../assistantChat/hooks/useAssistantField';
import MediaInputHook from 'src/components/common/CustomInput/hooks/MediaInputHook';

interface EditCreativeModalProps {
	isOpen: boolean;
	onClose: () => void;
	initialValues: ICreative;
	config?: IChannelCreativeAttributes;
	handleRefetchCreatives: () => void;
}

const EditCreativeModal: React.FC<EditCreativeModalProps> = ({
	isOpen,
	onClose,
	initialValues,
	config,
	handleRefetchCreatives,
}) => {
	const { id: campaignId } = useContext(CampaignContext);

	const [editedCreative, setEditedCreative] =
		useState<ICreative>(initialValues);
	const [designerMode, setDesignerMode] = useState(false);
	const [currentFieldIndex, setCurrentFieldIndex] = useState<number>(0);
	const [collapsedGroups, setCollapsedGroups] = useState<
		Record<string, boolean>
	>({});
	const { closeChat } = useContext(AssistantChatContext);
	const { user } = useContext(UserContext);
	const [font, setFont] = useState<string | undefined>(undefined);
	const [textLayerContent, setTextLayerContent] = useState<any>([]);
	// const [currentCreative, setCurrentCreative] =
	// 	useState<ICreative>(initialValues);
	const [isChangingImageLayer, setIsChangingImageLayer] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const creativeFormToggle = useToggleWithPayload<ICreative>(editedCreative);
	const [previewPSDMeta, setPreviewPSDMeta] = useState<IPerview | undefined>(
		undefined,
	);
	const fields =
		(editedCreative as any).attributes.output ?? editedCreative.attributes;
	const firstInputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (firstInputRef.current) {
			firstInputRef.current.focus();
		}
	}, []);

	const getLayersWithParents = (type: string) => {
		const layersCopy = [...(fields.image?.layers ?? [])];

		return layersCopy
			.filter((layer) => layer.type === type && layer.primary)
			.map((layer) => {
				const parents = [];
				let currentLayer = layer;

				while (currentLayer?.parentName) {
					const parentLayer = layersCopy.find(
						(l) => l.name === currentLayer.parentName,
					);
					if (parentLayer) parents.unshift(currentLayer.parentName);
					currentLayer = parentLayer;
				}

				return {
					...layer,
					parents,
				};
			});
	};
	const [filteredTextLayers, setFilteredTextLayers] = useState<TextLayer[]>(
		getLayersWithParents('text'),
	);
	const [filteredMediaLayers, setFilteredMediaLayers] = useState<ImageLayer[]>(
		getLayersWithParents('image'),
	);

	const mediaContent =
		editedCreative?.attributes as unknown as IChannelMediaAttributes;

	useEffect(() => {
		if (isSaving) {
			try {
				handleEditCreative(editedCreative);
			} catch (error) {
				toastError(error);
				setIsSaving(false);
			}
		}
	}, [isSaving]);

	const handleEditCreative = (creative: ICreative) => {
		try {
			if (editedCreative) {
				const payload: ICreativePayload = {
					creative: editedCreative,
					psdPerview: previewPSDMeta
						? {
								flatFile: previewPSDMeta.flatFile,
								layeredFile: previewPSDMeta.layeredFile,
								layers: [...filteredTextLayers, ...filteredMediaLayers],
						  }
						: undefined,
					text: filteredTextLayers,
					image: filteredMediaLayers,
					actions: {
						action: 'changeDD',
						generateCreatives: false,
						editMode: false,
						changeDD: true,
					},
				};
				changeTextLayer(payload, creative);
			} else {
				setEditedCreative((prev) => {
					if (prev?.id === creative.id) return prev;
					return creative;
				});
				setIsSaving(false);
			}
		} catch (error) {
			console.error(error);
			setIsSaving(false);
		}
	};

	const handleChangeMediaImage = (
		flatFile: string,
		layeredFile: string,
		layers: (ImageLayer | TextLayer)[],
	) => {
		if (isChangingImageLayer) {
			return;
		}

		setPreviewPSDMeta((prevState) => ({
			...prevState,
			flatFile: flatFile,
			layeredFile: layeredFile,
			layers: layers,
		}));

		setIsChangingImageLayer(true);

		const payload: ICreativePayload = {
			creative: editedCreative,
			psdPerview: {
				flatFile: flatFile,
				layeredFile: layeredFile,
				layers: layers,
			},
			text: filteredTextLayers,
			image: filteredMediaLayers,
			actions: {
				action: 'changeImage',
				generateCreatives: false,
				editMode: false,
			},
		};

		changeTextLayer(payload, editedCreative);
	};

	const changeTextLayer = async (
		payload: ICreativePayload,
		creative?: ICreative,
	) => {
		if (!payload.psdPerview && campaignId && creative) {
			const fields = (creative as any).attributes.output ?? creative.attributes;
			const updatePayload = {
				attributes: {
					...fields,
				},
			};
			await updateCampaignCreative(campaignId, creative.id, updatePayload);
			handleRefetchCreatives();
			onClose();
			return;
		}
		const textValues = payload.text.map((layer) => ({
			value: layer.content,
			layer: layer.name,
			visible: layer.visible,
			property: 'text',
		}));
		const imageValues = payload.image?.map((layer) => ({
			value: layer.imageUrl,
			layer: layer.name,
			visible: layer.visible,
			property: 'image',
		}));

		const values = [...textValues, ...(imageValues ?? [])];

		const changeLayerPayload = {
			inputs: {
				layeredFile:
					payload.psdPerview?.layeredFile ??
					(editedCreative as any).attributes.layeredFile,
				values,
			},
		};

		try {
			const { data } = await http.post(
				'/v2/apps/fusion_ai.edit_psd/execute/edit_psd',
				changeLayerPayload,
			);
			if (data) await processCallback(data.callback, payload, creative);
		} catch (error) {
			toastError(error);
			setIsSaving(false);
		}
	};

	const updateCreative = async (payload: IPerview, creative: ICreative) => {
		const attributes =
			creative?.attributes as unknown as IChannelMediaAttributes;
		if (!attributes || !campaignId) return;

		const updatePayload = {
			attributes: {
				...attributes,
				image: {
					...attributes.image,
					layeredFile: payload.layeredFile,
					flatFile: payload.flatFile,
					layers: payload.layers,
				},
			},
		};

		await updateCampaignCreative(campaignId, creative.id, updatePayload);
		handleRefetchCreatives();
		onClose();
	};

	const processCallback = async (
		callbackUrl: string,
		payload: ICreativePayload,
		creative?: ICreative,
	) => {
		try {
			const response = await http.get(callbackUrl);
			const { status, body } = response.data;
			if (status === 'processing' || status === 'pending') {
				setTimeout(
					processCallback.bind(null, callbackUrl, payload, creative),
					500,
				);
			} else if (status === 'error' || status === 'failed') {
				customToast('Error processing image', 'error');
				setIsSaving(false);
			} else if (status === 'successful') {
				const updatedTextLayers = filteredTextLayers.map((layer) => {
					return {
						...layer,
						content:
							payload.text.find((l) => l.name === layer.name)?.content ??
							layer.content,
					};
				});
				const updatedMediaLayers = filteredMediaLayers.map(
					(layer: ImageLayer) => {
						return {
							...layer,
							content:
								payload.image?.find((l) => l.name === layer.name)?.imageUrl ??
								layer.imageUrl,
						};
					},
				);

				const layers = [...updatedTextLayers, ...updatedMediaLayers];

				setPreviewPSDMeta((prevState) => ({
					...prevState,
					flatFile: body.flatFile,
					layeredFile: body.layeredFile,
					layers,
				}));
				if (isSaving) {
					await updateCreative(
						{
							flatFile: body.flatFile,
							layeredFile: body.layeredFile,
							layers,
						},
						payload.creative,
					);
					toastSuccess('Your changes have been saved');
					setIsSaving(false);
					onClose();
				}

				payload.actions.changeDD &&
					creative &&
					setEditedCreative((prev) => {
						if (prev?.id === creative.id) return prev;
						return creative;
					});
			}
		} catch (e) {
			console.error({ e });
			toastError(e);
			setIsSaving(false);
		}
	};

	useEffect(() => {
		const image = mediaContent?.image || {};

		if (editedCreative && image && image.layeredFile) {
			setPreviewPSDMeta((prevState) => ({
				...prevState,
				flatFile: image.flatFile,
				layeredFile: image.layeredFile,
				layers: image.layers,
			}));
		}
	}, [mediaContent?.image?.layeredFile]);

	useEffect(() => {
		getGlobalFont().then((font) => setFont(font));
	}, []);

	useEffect(() => {
		creativeFormToggle.onOpen(editedCreative);
	}, [designerMode]);

	const getGlobalFont = async () => {
		if (!user) return;
		const { brandIdentity } = await getCompanyAccount(user.account);
		return brandIdentity?.fontType;
	};

	const handleCloseEditModal = () => {
		closeChat();
		onClose();
	};

	const handleCloseDesignerMode = () => {
		creativeFormToggle.onClose();
		setDesignerMode(false);
		handleCloseEditModal();
	};
	const CTAOptions =
		config?.creatives
			?.find((c) => c.app === initialValues.app)
			?.attributes?.find((att) => att.property === 'callToAction')?.options ??
		[];

	const handleChangeTextLayerContent = useCallback(() => {
		document.getElementById('change-text-layer-button-preview')?.click();
	}, []);

	const handleChange = (value: string, field: string) => {
		setEditedCreative((prevState) => {
			if (field.includes('[')) {
				const [key, index] = field.split(/\[|\]/).filter(Boolean);
				const arrayField = (prevState as any).attributes[
					key as keyof CommonAttributes
				];

				if (Array.isArray(arrayField)) {
					const updatedArray = [...arrayField];
					updatedArray[parseInt(index)] = value;

					setCurrentFieldIndex(parseInt(index));

					return {
						...prevState,
						attributes: {
							...prevState.attributes,
							[key]: updatedArray,
						},
					};
				}
			}

			return {
				...prevState,
				attributes: {
					...prevState.attributes,
					[field as keyof CommonAttributes]: value,
				},
			};
		});
	};

	const toggleCollapse = (key: string) => {
		setCollapsedGroups((prevState) => ({
			...prevState,
			[key]: !prevState[key],
		}));
	};

	const renderInputField = (
		key: string,
		value: any,
		config: any,
		maxLength?: number,
	) => {
		if (config.inputType === 'dropdown' && CTAOptions) {
			return (
				<Box key={key}>
					<FormLabel>{config.label}</FormLabel>
					<SelectSearchInput
						name={key}
						placeholder="Select/search"
						options={CTAOptions.map((opt) => ({
							label: opt.value,
							value: opt.id,
						}))}
						value={(editedCreative.attributes as any)[key]}
						onChange={(value) => handleChange(String(value), key)}
					/>
				</Box>
			);
		}
		return (
			<InputField
				key={key}
				label={config.label}
				value={String(value)}
				onChange={(value) => handleChange(value, key)}
				isTextarea={config.inputType === 'textarea'}
				maxLength={maxLength}
				withAssistant
				name={key}
				creativeData={initialValues}
			/>
		);
	};

	const formMethods = useForm({
		defaultValues: filteredTextLayers.reduce(
			(acc: Record<string, string>, layer) => {
				acc[layer.name] = layer.content;
				return acc;
			},
			{},
		),
	});

	useEffect(() => {
		formMethods.reset(
			filteredTextLayers.reduce((acc: Record<string, string>, layer) => {
				acc[layer.name] = layer.content;
				return acc;
			}, {}),
		);
	}, [filteredTextLayers, formMethods]);

	const handleTextLayersChange = useCallback(() => {
		handleChangeTextLayerContent();
	}, [formMethods, filteredTextLayers, handleChangeTextLayerContent]);

	const setFieldValue = useCallback(
		(field: string, value: string) => {
			setFilteredTextLayers((prevLayers) =>
				prevLayers.map((layer) =>
					layer.name === field ? { ...layer, content: value } : layer,
				),
			);
			formMethods.setValue(field, value);
			handleTextLayersChange();
		},
		[formMethods, handleTextLayersChange],
	);

	const { control } = formMethods;

	const toggleShowMediaLayer = (layerId: number) => {
		setFilteredMediaLayers((prevState) =>
			prevState.map((layer) =>
				layer.id === layerId ? { ...layer, visible: !layer.visible } : layer,
			),
		);
	};

	const toggleShowTextLayer = (layerId: string) => {
		setFilteredTextLayers((prevState) =>
			prevState.map((layer) =>
				layer.id === layerId ? { ...layer, visible: !layer.visible } : layer,
			),
		);
	};

	const renderInputsBasedOnAttributes = (
		validationConfig?: IChannelCreativeAttributes,
	) => {
		const nonImageFields = Object.keys(fields)
			.filter((key) => key !== 'image')
			.map((key, index) => {
				const attribute = validationConfig?.creatives[0].attributes.find(
					(att) => att.property === key,
				);

				const maxLength = attribute?.validations.find(
					(validation) => validation.id === 'String.maxLength',
				)?.value;

				const maxLengthNumber =
					typeof maxLength === 'number' ? maxLength : undefined;

				const value = (editedCreative as any).attributes[
					key as keyof CommonAttributes
				];
				const config = attributeConfig[key as keyof typeof attributeConfig] || {
					label: formatLabel(key),
					inputType: 'string',
				};

				// const openAssistant = (layer: any) =>
				// 	useAssistantField({
				// 		type: ThreadTypesEnum.Campaign,
				// 		reference: campaignId ?? '',
				// 		fieldName: layer.name,
				// 		control,
				// 		variant: editedCreative.variant,
				// 		channel: editedCreative.channel,
				// 		displayName: layer.displayName,
				// 	});

				if (Array.isArray(value)) {
					return (
						<Box
							key={key}
							p={2}
							borderRadius="md"
							onClick={() => toggleCollapse(key)}
							cursor="pointer"
						>
							<Flex justifyContent="space-between">
								<FormLabel mb={0}>{config.label}</FormLabel>
								<Icon
									boxSize={5}
									aria-label={`Toggle ${key}`}
									as={collapsedGroups[key] ? ChevronDownIcon : ChevronUpIcon}
								/>
							</Flex>
							<Collapse in={!collapsedGroups[key]} animateOpacity>
								<VStack mt={2} align="stretch">
									{value.map((item, index) => {
										return (
											<InputField
												key={`${key}-${index}`}
												value={item}
												onChange={(value) =>
													handleChange(value, `${key}[${index}]`)
												}
												maxLength={maxLengthNumber}
												withAssistant
												name={`${key}[${index}]`}
												creativeData={initialValues}
											/>
											// <CustomInput
											// 	key={item.id}
											// 	ref={index === 0 ? firstInputRef : null}
											// 	control={formMethods.control}
											// 	label={item.displayName}
											// 	name={item.name}
											// 	isLayerVisible={item.visible}
											// 	onCopilotClick={() => openAssistant(item)}
											// 	onChange={(value) => setFieldValue(item.name, value)}
											// />
										);
									})}
								</VStack>
							</Collapse>
						</Box>
					);
				}

				return renderInputField(key, value, config, maxLengthNumber);
			});
		const imageField = Object.keys(fields).includes('image')
			? (() => {
					const openAssistant = (layer: TextLayer) =>
						useAssistantField({
							type: ThreadTypesEnum.Campaign,
							reference: campaignId ?? '',
							fieldName: layer.name,
							control,
							variant: editedCreative.variant,
							channel: editedCreative.channel,
							displayName: layer.displayName,
						});
					return (
						<FormProvider key="image" {...formMethods}>
							<Text size="md" fontWeight="bold" mt={5}>
								Image
							</Text>
							<VStack spacing={6} align="stretch">
								{filteredTextLayers.map((layer, index) => {
									return (
										<CustomInput
											key={layer.id}
											control={formMethods.control}
											label={layer.displayName}
											name={layer.name}
											isLayerVisible={layer.visible}
											onCopilotClick={() => openAssistant(layer)}
											onShowClick={() => toggleShowTextLayer(layer.id)}
											onChange={(value) => setFieldValue(layer.name, value)}
										/>
									);
								})}
								{previewPSDMeta &&
									filteredMediaLayers.map((layer) => {
										return (
											<MediaInputHook
												key={layer.id}
												control={formMethods.control}
												onShowClick={() => toggleShowMediaLayer(layer.id!)}
												onChangePreviewPSD={handleChangeMediaImage}
												previewPSD={previewPSDMeta}
												layer={layer}
												onChangingLayer={setIsChangingImageLayer}
											/>
										);
									})}
							</VStack>
						</FormProvider>
					);
			  })()
			: null;

		return (
			<>
				{nonImageFields}
				{imageField}
			</>
		);
	};

	const modalHeader = (
		<HStack pr={10}>
			<Text size="md">
				<Text as="span" size="md" fontWeight="bold">
					Editing:{' '}
				</Text>
				Option {initialValues?.variant} - {initialValues?.name}
			</Text>
			<Spacer />
			{previewPSDMeta && (
				<Button
					size="sm"
					variant="blankOutline"
					onClick={() => setDesignerMode(true)}
				>
					Switch to designer mode
				</Button>
			)}
		</HStack>
	);

	const modalFooter = (
		<>
			<Button onClick={handleCloseEditModal} mr={3}>
				Close
			</Button>
			<Button
				isLoading={isSaving}
				onClick={() => setIsSaving(true)}
				variant="orangeSolid"
			>
				Save changes
			</Button>
		</>
	);
	return (
		<CustomModal
			isOpen={isOpen}
			onClose={handleCloseEditModal}
			size="4xl"
			header={modalHeader}
			footer={modalFooter}
			trapFocus={false}
		>
			{designerMode && previewPSDMeta && creativeFormToggle.payload ? (
				<MediaCreativeModal
					isOpen={creativeFormToggle.isOpen}
					data={creativeFormToggle.payload}
					onClose={handleCloseDesignerMode}
					onRefetchCreatives={handleRefetchCreatives}
					layeredFile={previewPSDMeta.layeredFile}
					textLayerContent={textLayerContent}
					font={font}
					closeEditModal={handleCloseEditModal}
					isCreative
				/>
			) : (
				<Flex gap={5}>
					<Box
						flex={1}
						maxH="70vh"
						overflowY="auto"
						pr={4}
						sx={{
							'&::-webkit-scrollbar': {
								width: '10px',
							},
							'&::-webkit-scrollbar-track': {
								background: '#f1f1f1',
								borderRadius: '8px',
							},
							'&::-webkit-scrollbar-thumb': {
								background: '#888',
								borderRadius: '8px',
							},
							'&::-webkit-scrollbar-thumb:hover': {
								background: '#555',
							},
						}}
					>
						<VStack spacing={6} align="stretch">
							{renderInputsBasedOnAttributes(config)}
						</VStack>
					</Box>
					<Flex flex={1} justifyContent="flex-end">
						{renderCreativesByPlacement({
							creatives: [editedCreative],
							withActions: false,
							size: 'sm',
							fieldIndex: currentFieldIndex,
							layeredFile: previewPSDMeta?.layeredFile,
							textLayerContent: filteredTextLayers,
							mediaLayersContent: filteredMediaLayers,
							font,
							designDirection: editedCreative,
							isChangingLayer: isChangingImageLayer,
							editMode: true,
							onRefetchCreatives: handleRefetchCreatives,
						})}
					</Flex>
				</Flex>
			)}
		</CustomModal>
	);
};

export default EditCreativeModal;
