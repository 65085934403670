import LoadingList from 'src/components/campaigns/design-directions/LoadingList';

const LoadingCard = () => (
	<LoadingList
		text1="Scanning your website"
		text2="Processing content"
		text3="Generating image"
		height="200px"
		width="200px"
		size="sm"
	/>
);

export default LoadingCard;
